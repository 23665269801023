import React from "react"
import Layout from "../components/Layout"
import { Box, Flex, Text, Button, Heading } from "rebass"
import PhoneScreenshot from "../components/PhoneScreenshot"
import Contact from "../components/Contact"

const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="600px"
    viewBox="0 0 359.63 674.55"
  >
    <g data-name="Layer 2">
      <path
        d="M1.42 657.73V37a15.39 15.39 0 0115.4-15.4h62.23A15.4 15.4 0 0194.46 37v5.23a15.4 15.4 0 0015.4 15.41h136.73A15.41 15.41 0 00262 42.23V37a15.39 15.39 0 0115.4-15.4h65.41a15.39 15.39 0 0115.4 15.4v595.84a15.4 15.4 0 01-15.4 15.41H41.47a15.41 15.41 0 01-15.41-15.41L24 16.82a15.41 15.41 0 0115.41-15.4h59.11a15.41 15.41 0 0115.41 15.4v10.1a15.4 15.4 0 0015.41 15.4h100a15.4 15.4 0 0015.4-15.4v-10.1c0-8.5 4.84-15.4 13.35-15.4h59.49A15.41 15.41 0 01333 16.82v640.91a15.41 15.41 0 01-15.41 15.4H16.82a15.4 15.4 0 01-15.4-15.4z"
        fill="none"
        stroke="#4C55FF"
        stroke-width="2.83"
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export default function Fenella() {
  return (
    <Layout>
      <Flex
        color="secondary"
        backgroundColor="primary"
        px={9}
        pb={8}
        justifyContent="space-between"
        sx={{ position: "relative" }}
      >
        <Flex
          flexDirection="column"
          width={4 / 7}
          textAlign="center"
          alignItems="center"
        >
          <Heading fontSize={6} mb={3}>
            Fenella
          </Heading>
          <Box width={"350px"} mb={6}>
            <Text color="white" variant="primaryText" mb={3} fontSize={2}>
              Fenella Jewellery is a luxury jewellers born out of the heart of
              Hatton Gardens.
            </Text>
            <Text color="white" variant="primaryText" fontSize={2}>
              With our second phase commencing in Early 2020, we will make
              Fenella into a fully functioning E-Commerce platform as well as
              automating Fenella’s manufacturing process.
            </Text>
          </Box>

          <Flex
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            px={5}
          >
            <Flex flexDirection="column">
              <Text
                variant="largeHeading"
                color="secondary"
                fontSize={6}
                mb={3}
              >
                98%
              </Text>
              <Text variant="subHeading" color="secondary" fontSize={"8px"}>
                Sub Heading here
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <Text
                variant="largeHeading"
                color="secondary"
                fontSize={6}
                mb={3}
              >
                120,000
              </Text>
              <Text variant="subHeading" color="secondary" fontSize={"8px"}>
                Sub Heading here
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <Text
                variant="largeHeading"
                color="secondary"
                fontSize={6}
                mb={3}
              >
                75m
              </Text>
              <Text variant="subHeading" color="secondary" fontSize={"8px"}>
                Sub Heading here
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex sx={{ position: "absolute", right: "120px" }}>
          <PhoneScreenshot />
        </Flex>
      </Flex>
      <Contact />
    </Layout>
  )
}
