import React from "react"
import { Box, Flex, Image } from "rebass"
import fenellaPhoneScreen from "../assets/fenellaPhoneScreen.png"

const Mask = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="309" height="647">
    <defs>
      <clipPath id="phoneMask">
        <path d="M17.47 646.83H309V15.4a15.397 15.397 0 00-4.521-10.894A15.4 15.4 0 00293.58 0h-59.49c-8.51 0-13.35 6.9-13.35 15.4v10.1a15.4 15.4 0 01-15.4 15.4h-100a15.4 15.4 0 01-15.41-15.4V15.4A15.41 15.41 0 0074.52 0H15.41A15.41 15.41 0 000 15.4l2.06 616.02a15.411 15.411 0 0015.41 15.41z" />
      </clipPath>
    </defs>
  </svg>
)

const PhoneFrame = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="361" height="676" fill="none">
    <path
      d="M2 658.31V37.58a15.39 15.39 0 0115.4-15.4h62.23a15.401 15.401 0 0115.41 15.4v5.23a15.401 15.401 0 0015.4 15.41h136.73a15.411 15.411 0 0015.41-15.41v-5.23a15.386 15.386 0 014.508-10.892 15.384 15.384 0 0110.892-4.508h65.41a15.389 15.389 0 0115.4 15.4v595.84a15.408 15.408 0 01-4.507 10.896 15.406 15.406 0 01-10.893 4.514H42.05a15.413 15.413 0 01-15.41-15.41L24.58 17.4A15.41 15.41 0 0139.99 2H99.1a15.411 15.411 0 0115.41 15.4v10.1a15.41 15.41 0 004.514 10.893A15.397 15.397 0 00129.92 42.9h100a15.4 15.4 0 0015.4-15.4V17.4c0-8.5 4.84-15.4 13.35-15.4h59.49a15.409 15.409 0 0115.42 15.4v640.91a15.41 15.41 0 01-15.41 15.4H17.4A15.397 15.397 0 012 658.31v0z"
      stroke="#4C55FF"
      stroke-width="2.83"
    />
  </svg>
)

const WIDTH = 361
const ASPECT = 1.87

export default function PhoneScreenshot() {
  return (
    <Box
      sx={{ position: "relative", zIndex: 100 }}
      width={WIDTH}
      height={WIDTH * ASPECT}
    >
      <Flex
        justifyContent="center"
        sx={{
          zIndex: -10,
          position: "absolute",
          top: 1.415,
          bottom: 0,
          left: -1.415,
          right: 0,
        }}
      >
        <Box sx={{ clipPath: "url(#phoneMask)", position: "relative" }}>
          <Flex
            flexDirection="column"
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#DAE3E8",
                height: 0.21 * WIDTH,
              }}
            ></Box>
            <Box flex="1" sx={{ position: "relative" }}>
              <Image
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                }}
                src={fenellaPhoneScreen}
              ></Image>
            </Box>
          </Flex>
          <Mask />
        </Box>
      </Flex>
      <Box sx={{ zIndex: 2 }}>
        <PhoneFrame />
      </Box>
    </Box>
  )
}
