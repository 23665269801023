import React, { useState } from "react"
import { Flex, Heading, Text, Box, Button } from "rebass"

export default function Contact() {
  return (
    <Flex
      bg="mainBackground"
      flexDirection={["column", "row"]}
      textAlign={["center", "left"]}
      py={6}
      px={[4, 6]}
    >
      <Heading
        color="secondary"
        fontSize={6}
        py={[5, 0]}
        pr={[0, 6]}
        lineHeight="1.25"
      >
        Contact us
      </Heading>
      <Flex flexDirection="column" width={["auto", "50%"]}>
        <Text fontSize={[6]} mb={4} variant="primaryText">
          We’re always happy to chat, so get in touch if you have a project in
          mind.
        </Text>
        <Box>
          <a href="/contact">
            <Button variant="primary">CONTACT</Button>
          </a>
        </Box>
      </Flex>
    </Flex>
  )
}
